export enum PMProjectStatusEnum {
    decidingLaunch = 1, // Принимается решение о запуске
    designStarted, // Запущено проектирование
    constructionUnderway, // Ведется строительство
    workStopped, // Работы остановлены
    completed, // Завершен
}

export enum PMChartStatusEnum {
    planned = 1, // Запланирован
    approved, // Утвержден
    stopped, // Остановлен
    completed, // Завершен
}

export enum PMChartBpcStatusEnum {
    prepared = 1, // подготовлен
    onApproval, // на согласовании
    agreed, // согласован
    notAgreed, // не согласован
}

export enum PMTableActionEnum {
    open,
    openStructure,
    history,
}

export enum PMDocumentTypeEnum {
    project = 1,
    chart,
}

export enum PMDocumentPropertyEnum {
    project,
    chart,
    bpc,
}

export enum PMPlanChartEnum {
    bpc = 'base-schedule',
    dpcQuart = 'detailed-schedule',
    dpcExec = 'detailed-schedule-execution',
}

export enum PMTabTypeEnum {
    chartInfo = 1, //: 'Информация о графике'
    structure, //: 'Структура'
    bpc, //: 'b p g'
    dpcQuart, //: 'Квартальный ДПГ'
    dpcExec, //: 'Исполнение ДПГ'
    weeklyReport, //: 'Недельный отчет'
}

export enum PMChartTypeEnum {
    consolidated = 1, // Сводный
    design, // Проектирование
    construction, // Строительство
    tenders, // Тендеры
}

export enum PMCalendarTypeEnum {
    fiveDays = 5,
    sevenDays = 7,
}

export enum PMReportTypeEnum {
    bpc,
    dpcExec,
    dpcQuart,
    weeklyReport,
}

export enum PMChartVersionTypeEnum {
    current = 1,
    approved,
    other,
}

export enum PMTableRowCellType {
    date,
    user,
    status,
    checkbox,
    bool,
}

export enum PMChartModalActionEnum {
    Form,
    SendForApproval,
}

export enum PMChartVersionApprovalStatusEnum {
    none = 1, // 1 - не началось
    onApproval, // 2 - началось
    approved, // 3 - согласовано
}

export enum PMChartPageModeEnum {
    creation = 'creation',
    show = 'show',
}

export enum PMTaskTypeEnum {
    project = 1, // Проектная задача
    stage, // Этап
    milestone, // Веха
    tenders, // Тендеры
}

export enum PMStructureTypeEnum {
    Task = 1,
    Stage,
    Mark,
}

export enum CreateTaskActions {
    CreateTaskUpper,
    CreateTaskLower,
    CreateTaskInner,
}

export enum TogglerGanttTable {
    Table = 1,
    Gantt,
}

export enum HeaderTabsEnum {
    Progress, // Прогресс
    Connections, // Связи и старт
    Settings, // Настройки
    History, // История
}

export enum PMStructureModeEnum {
    Manually = 1,
    Automatically,
}

export enum PMStructureLinkLocationEnum {
    ThisChart = 1,
    OtherChart,
}
